






























































































































































import { API, ServiceManager } from '@/app_code/services'
import { View } from '@/app_code/services/meta/view'
import ServiceType from '@/app_code/services/ServiceTypes'
import { Component, Mixins } from 'vue-property-decorator'
import ImageCard from '../components/cards/ImageCard.vue'
import SideBySide from '../components/layout/SideBySide.vue'
import Topic from '../components/Topic.vue'
import NumberIncrement from '@/components/NumberIncrement.vue'

@Component({
  components: {
    Topic,
    SideBySide,
    ImageCard,
    NumberIncrement
  }
})
export default class FAQ extends Mixins(View) {
  private unitCount = 0
  private trainingPackagesCount = 0

  private api = ServiceManager.Require<API>(ServiceType.API)

  private runningCount = 0;
  private millisecondTillComplete = 1000;

  mounted (): void {
    this.getCategories()
    this.getUnitCount()
  }

  getCategories (): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.api.UnitsAPI.GetCategories(true)
        .then(data => {
          this.trainingPackagesCount = data?.length ?? 0
          resolve(true)
        })
        .catch(reject)
    })
  }

  getUnitCount (): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.api.UnitsAPI.GetUnits(1, '', '00000000-0000-0000-000000000000')
        .then(data => {
          this.unitCount = data?.totalItems ?? 0
          resolve(true)
        })
        .catch(reject)
    })
  }
}
